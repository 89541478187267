import { Fragment, useEffect, useState } from "react";


import ArticleDefault from 				"./Articles/ArticleDefault";
import ArticleText from 				"./Articles/ArticleText";


function ArticlePropagator( props ){

	const [component, setComponent] = useState(null);

	useEffect(() => {

		const sections = {
			default:					ArticleDefault,
			article_text:				ArticleText
		};

		let Component = sections[ props.translation.content_display ] || ArticleDefault;

		setComponent( 
			<Component 
				key={`comp_${Math.random()}`} 
				article={props.translation} 
				{...props}
			/>
		);

	// eslint-disable-next-line
	}, [props.translation])

	return (
		<Fragment>
		
			{ component !== null && {...component} }

		</Fragment>
	);

}

export default ArticlePropagator;