import { useEffect } from 'react';
import { useLocation } from "react-router-dom";


function RouterWrapper(props) {

	const location = useLocation();

	useEffect(() => {
		let segments = location.pathname.substring(1).split('/');
		document.querySelector('.main').dataset.slug = ( segments.length >= 2 && segments[1] !== '') 
			? segments[1] 
			: "home";
	}, [location])

	return props.children;
		
}
	
export default RouterWrapper;