import { Fragment, useEffect, useState } from "react";



import { mediaUrl, cdnUrl, cdnParams } from '../Helpers/General';


function Media( props ){

	const [ mediaElem, setMediaElem] = useState(null);
	const [ mediaType, setMediaType] = useState("");

	const [ isLoading, setIsLoading] = useState(false);


	const renderFooter = () => {
		const mediaParams = JSON.parse( props.media.params );
		const cdnPath = `${cdnUrl}${props.media.path}${cdnParams}${props.cdnParams || ''}`;

		return <div className="media-item__footer">

			{ mediaParams.hasOwnProperty('lightbox') && parseInt(mediaParams.lightbox) === 1 &&
				<span 
					data-lightbox
					data-lightbox-src={cdnPath}
					data-lightbox-title={(mediaParams.title !== '' && mediaParams.hasOwnProperty('title') && parseInt(mediaParams.title) === 1) ? props.media.title : ''}
				>
					EXPAND
				</span>
			}

			{ props.thumb !== true && mediaParams.title !== '' && mediaParams.hasOwnProperty('title') && parseInt(mediaParams.title) === 1 &&
				<small>{props.media.title}</small>
			}

		</div>
	}


	useEffect(() => {

		const cdnPath = `${cdnUrl}${props.media.path}${cdnParams}${props.cdnParams || ''}`;
		const mediaPath = `${mediaUrl}${props.media.path}`;
		const mediaParams = JSON.parse( props.media.params );

		// ----- embeds
		if( 	props.media.embed !== '' ){
			setMediaType('embed');
			setMediaElem( 
				<Fragment>
					<div 	className="media-item" 
							dangerouslySetInnerHTML={{__html:props.media.embed}} 
					/> 

					{renderFooter()}
				</Fragment>
			);
		}

		// ----- audio
		else if( 	props.media.path.includes('.wav') 
					|| props.media.path.includes('.mp3') 
		){
			setMediaType('audio');
			setMediaElem( 
				<Fragment>
					<audio 	className="media-item" 
							src={mediaPath} 
							controls 
							loading="lazy"
					/>

					{renderFooter()}
				</Fragment>
			);
		}

		// ----- image
		else if( 	props.media.path.includes('.jpg') 
					|| props.media.path.includes('.jpeg') 
					|| props.media.path.includes('.png') 
					|| props.media.path.includes('.gif') 
		){
			if( props.loader ){
				setIsLoading(true);
			}

			setMediaType('image');
			setMediaElem( 
				<Fragment>
					<img 	className="media-item" 
							src={cdnPath} 
							alt={props.media.title}
							// onLoad={null}
							ref={(input) => {
								if (!input) { return; }
								input.addEventListener('load', (e) => {
									setIsLoading(false);
								}, {once: true});
								input.addEventListener('error', (e) => {
									setIsLoading(false);
								}, {once: true});
							}}
							loading={ (props.lazyload === true) ? "lazy" : "eager" }
							width="auto"
							height="100%"
					/>

					{renderFooter()}
				</Fragment>
			);
		}

		// ----- documents
		else if( 	props.media.path.includes('.zip') 
					|| props.media.path.includes('.pdf') 
					|| props.media.path.includes('.doc') 
					|| props.media.path.includes('.docx') 
					|| props.media.path.includes('.ppt') 
		){
			setMediaType(
				( mediaParams.hasOwnProperty('lightbox') && parseInt(mediaParams.lightbox) === 1 ) ? 'lightbox' : 'download'
			);
			setMediaElem( 
				<Fragment>
					{ parseInt(mediaParams.download) === 1 && 
						<a href={mediaPath} download target="_blank" rel="noreferrer">
							<div className="media-item__icon">
								DOWNLOAD
							</div>

							{renderFooter()}
						</a>
					}
				</Fragment>
			);
		}

		// ----- unknown
		else{
			setMediaType('unknown');
			setMediaElem( 
				<span>
					{props.media.path}
				</span>
			);
		}

	// eslint-disable-next-line
	}, [props.media])


	return (
		<div 
			className={`media media--${mediaType} media--loading-${isLoading} `} 
			key={`media_${props.media.id}`}>

			{ props.loader === true && isLoading === true && 
				<span>REFRESH</span>
			}

			{ mediaElem !== null &&
				mediaElem
			}
			
		</div>
	);

}

export default Media;