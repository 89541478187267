import Main from './Containers/Main';
import './styles/index.scss';

function App() {
  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
