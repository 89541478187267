import { Fragment, useEffect, useState } from 'react';
import { 
    BrowserRouter, 
    /*,Routes, Route, NavLink*/ 
} from "react-router-dom";
import history from './../history';
import axios from 'axios';
import { 
    apiUrl
} from '../Helpers/General';
import {
    defaultLang
} from '../Helpers/Lang';
import RouterWrapper from '../Containers/RouterWrapper';


// import ArticleDefault from 				'./../Components/Articles/ArticleDefault';

import Home from                        '../Pages/Home';
import Contact from                     '../Pages/Contact';
import Sidebar from                     '../Components/Sidebar';
import ArticlePropagator from '../Components/ArticlePropagator';


function Main() {
    
    const [lang, 	setLang] = useState(null);
    const [data,    setData] = useState(null);
    const [page,    setPage] = useState(null);
    const [menuVisible,    setMenuVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    
    useEffect(() => {
        let urlSegments = window.location.href.split('/');
        let lang = ( urlSegments?.[3] !== undefined && urlSegments[3] !== '' ) 
            ? urlSegments[3] 
            : defaultLang;
        switchLang( lang );

        document.addEventListener('click', (e) => {
            if( e.target !== null 
                && document.querySelector('.sidebar').contains(e.target) === false
                && e.target.getAttribute('class') !== null
                && e.target.getAttribute('class').includes('sidebar') === false
            ){
                setMenuVisible(false);
            }
        })
    // eslint-disable-next-line
    },[])



    const fetchContent = ( params ) => {
        if( lang !== null ){
            setIsLoading(true);
            setMenuVisible(false);
            axios.get(`${apiUrl}${lang}/api/${params.type}/${params.slug}${(params.hasSub===true)?'?sub=true':''}`)
                .then(res => res.data)
                .then(data => {
                    setData(data);
                    setPage(null);
                    setIsLoading(false);
                    setUrl( [lang, params.slug] );
                });
        }
    }

    const fetchCustomPage = ( params ) => {
        const pagesMap = {
            pocetna: <Home 
                lang={lang}
                fetchContent={fetchContent}
                fetchCustomPage={fetchCustomPage}
            />,
            homepage: <Home
                lang={lang} 
                fetchContent={fetchContent}
                fetchCustomPage={fetchCustomPage}
            />,
            kontakt: <Contact
                lang={lang} 
                fetchContent={fetchContent}
                fetchCustomPage={fetchCustomPage}
            />,
            contact: <Contact
                lang={lang} 
                fetchContent={fetchContent}
                fetchCustomPage={fetchCustomPage}
            />

        };
        
        setMenuVisible(false);
        setPage( pagesMap[params.slug] );
        setData(null);
        setUrl( [lang, params.slug] );
    }

    const setUrl = ( segments = [] ) => {
        history.push( `/${segments.join('/')}` );
    }

    const switchLang = (lang) => {
        setLang(lang);
        setUrl([lang]);
    }

    const setMenuVisibility = ( isOn = false ) => {
        setMenuVisible( isOn );
    }


    return (

        <div className="main">

            <BrowserRouter history={history} >
				<RouterWrapper>


                    <div className="wrapper">


                        <Sidebar 
                            menuVisible={menuVisible}
                            setMenuVisibility={setMenuVisibility}
                            lang={lang}
                            fetchContent={fetchContent}
                            fetchCustomPage={fetchCustomPage}
                            setUrl={setUrl}
                            switchLang={switchLang}
                        />

                        <div className="content">

                            {isLoading === true && <pre>LOADING CONTENT...</pre>}

                            {isLoading === false && page === null && data !== null && data.map( (dataItem, dIdx) => 
                                <Fragment key={`itm_${dIdx}`}>
                                    {dataItem.translations.map( (trns, tIdx) => 
                                        <ArticlePropagator 
                                            key={`art_${tIdx}`}
                                            article={trns} 
                                            translation={trns}
                                            dataItem={dataItem}
                                        />
                                    )}
                                </Fragment>
                            )}

                            {isLoading === false && page !== null && page }

                            {isLoading === false && data !== null && data.length === 0 && 
                                <pre>no content</pre>
                            }

                        </div>

                    </div>

                </RouterWrapper>
            </BrowserRouter>

        </div>

    );
}
    
export default Main;