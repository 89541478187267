import {useEffect, useState, Fragment} from "react";
// import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { ReactSVG } from 'react-svg'
import { 
    apiUrl
} from '../Helpers/General';
import { languages } from "../Helpers/Lang";
import { NavLink } from "react-router-dom";
// import { IoMenu } from "react-icons/io5";



function Sidebar(props){


	// const navigate = useNavigate();

	// const [currentSlug, setCurrentSlug] = useState('');
    const [lists, 	setLists] = useState([]);
    const [menu, 	setMenu] = useState([]);


	useEffect(() => {
        if( props.lang !== null && props.lang !== ""){
            axios.get(`${apiUrl}${props.lang}/api/menus`)
                .then(res => res.data)
                .then(data => {
                    setMenu(data);
                });

            axios.get(`${apiUrl}${props.lang}/api/lists`)
                .then(res => res.data)
                .then(data => {
                    setLists(data);
                });
        }
    // eslint-disable-next-line
    },[props.lang])


    useEffect(() => {
        let urlSegments = window.location.href.split('/');
        let btnElem = null;

        if( props.lang !== null && props.lang !== ""){

            if( urlSegments?.[4] !== undefined ){
                btnElem = document.querySelector(`[data-btn-slug="${urlSegments[4]}"]`);
                btnElem !== null && btnElem.click();
                // props.fetchContent( {slug: urlSegments[4], type: 'pages'} )
            }else{
                const pageSlug = (props.lang==='hr') ? 'pocetna' : 'homepage';
                // btnElem = document.querySelector(`[data-btn-slug="${pageSlug}"]`);
                props.fetchCustomPage({slug: pageSlug})
            }
        }
        
    // eslint-disable-next-line
    }, [props.lang, menu])


	useEffect(() => {
		let urlSegments = window.location.href.split('/');
		if( urlSegments[4] !== undefined ){
			document.querySelector('.main').setAttribute('data-slug', urlSegments[4]);
		}
	// eslint-disable-next-line
	},[window.location.href])


	/*
	const setPage = (page) => {
		navigate( `/${props.lang}/${page}` );
		window.scrollTo(window.scrollX, 0);
		// history.push( `${process.env.PUBLIC_URL}/${props.lang}/${page}` );
	}
	*/


    const renderCategories = ( categories, displayLevel, levelsMax = 99 ) => {

        return categories
            .map( (list, listIdx) => 
                <div key={`home_cat_${listIdx}`} data-sub-level={displayLevel}>

                    <button
                        key={`menu_list_${listIdx}`}
                        className={`${(list?.translations[0]?.slug === window.location.href.split('/')?.[4])?'highlight':''}`}
                        onClick={() => props.fetchContent({
                            slug: list?.translations[0]?.slug, 
                            type: 'items',
                            hasSub: true,
                        })}
                    >
                        {list.translations[0]?.title}
                    </button>

                    { displayLevel < levelsMax &&
                        <Fragment>
                        {renderCategories( 
                            lists.filter(x => x.parent_id === list.id), 
                            displayLevel + 1,
                            levelsMax,
                        )}
                        </Fragment>
                    }

                </div>
        )
    }


	return (
        <Fragment>

            { props.menuVisible === false && 
                <button 
                    className="sidebar-toggle"
                    onClick={() => props.setMenuVisibility( !props.menuVisible )}
                >
                    <ReactSVG src="/assets/menu.svg" />
                </button>
            }

            <div className={`sidebar ${(props.menuVisible) ? 'active' : ''} `}>

                <button
                    key={`menu_page_home`}
                    onClick={() => props.fetchCustomPage( {slug: (props.lang === "hr") ? 'pocetna' : 'homepage'} )}
                >
                    <ReactSVG src="/assets/menu_open.svg" />
                </button>
                                
                {menu !== undefined && menu
                    .filter( x => 
                        x.slug !== '' 
                        // && x.in_menu === "1" 
                        && ( x?.tags === undefined || x?.tags.includes('is_hidden') === false )
                    )
                    .map( (menuLink, pageIdx) => {
                        
                        const urlSegments = window.location.href.split('/');
                        const btnClass = (urlSegments.includes(menuLink.slug) ) ? 'highlight' : '';
                        const isPlaceholder = menuLink.tags.includes('placeholder');

                        if( isPlaceholder === false ){
                            return <button
                                key={`menu_page_${pageIdx}`}
                                data-btn-slug={menuLink.slug}
                                className={btnClass}
                                onClick={
                                    ( menuLink?.tags !== undefined && menuLink.tags.includes('custom_page') ) 
                                        ? () => props.fetchCustomPage( {slug: menuLink.slug} )
                                        : () => props.fetchContent( {slug: menuLink.slug, type: 'pages'} )
                                }
                            >
                                {menuLink.title}
                            </button>
                        }

                        if ( isPlaceholder && menuLink.slug === '{categories}' ){
                            return renderCategories( lists.filter(x => x.parent_id === "0" ), 1, 2)
                        }
                        
                })}

                <br />

                <div>
                {languages.length > 1 && 
                    languages
                        .map( (language, langIdx) => 
                        <button 
                            key={`footer_lang_${langIdx}`}
                            onClick={() => props.switchLang(language)}
                            className={` ${(props.lang === language) ? 'highlight' : ''}`}
                        >

                            {language}
                        </button>
                )}
                </div>

            </div>

        </Fragment>

	);

}

export default Sidebar;