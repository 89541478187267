import { useState, useEffect, useRef } from "react";
// import { useDraggable } from "react-use-draggable-scroll";
import { translations } from "../../Helpers/Lang";
import Media from '../Media';

function ArticleDefault ( props ){

	const detailsRef = useRef();
	const descriptionRef = useRef();
	const itemsRef = useRef({});

	const [ pCount, setPCount ] = useState(0);
	const [ detailsVisible, setDetailsVisible ] = useState(false);
	
	useEffect(() => {
		// window.scrollTo(window.scrollX, window.scrollY+1);
		// window.scrollTo(window.scrollX, window.scrollY-1);
		// eslint-disable-next-line

		const descElem = descriptionRef.current;
		if( descElem !== undefined ){
			const descElemParagraphs = descElem.querySelectorAll(':scope > p');
			setPCount( 
				Math.max( 
					pCount, 
					[...descElemParagraphs].length
				) 
			)
		}
		
	},[ pCount ])


	const categoryJson = ( props?.dataItem?.content_json !== undefined )
		? JSON.parse( props.dataItem.content_json )
		: null;

	return (
		<div 	className={`article article--${props.article.content_display || 'default'} ${props.addClass || ''} ${(detailsVisible && 'article--active')}`}> 

			{ props.articlesIndex !== undefined && props.articlesTotal !== undefined && 
				<small className="slider__item-count">
					{`${props.articlesIndex}/${props.articlesTotal}`}
				</small>
			}

			{ categoryJson !== null && 
				<small style={{ 
					background: '#222',
					color: '#fff',
					padding: '4px 8px',
					fontSize: '11px', 
					fontWeight: '700',
					textTransform: 'uppercase' 
				}}>
					&gt; {categoryJson.title}
				</small>
			}

			{ props.article.title !== '' && 
				<h2 id={props.article.slug}
					onClick={() => { setDetailsVisible( !detailsVisible ) }}
				>
					{props.article.title}
				</h2>
			}

			<div 
				ref={detailsRef}
				style={ (detailsVisible) ? {display: 'block'} :{display: 'none'} } 
			>

				{ props.article.subtitle !== '' && 
					<h3>{props.article.subtitle}</h3>
				}

				{/*<pre>{JSON.stringify(props.article)}</pre>*/}

				{ props.article.media.length > 0 && 
					<div className="article__media">
						{props.article.media.map( (media, mediaIdx) => 
							<div key={`article_media_${mediaIdx}_${media.id}`} ref={el => itemsRef.current[`media_${mediaIdx}_${media.id}`] = el}>
								<Media 
									key={`media_${mediaIdx}`} 
									media={media} 
									cdnParams="&width=600&func=crop"
								/>
							</div>
						)}
					</div>
				}
				
				{ props.article.description !== '' && 
					<p ref={descriptionRef} dangerouslySetInnerHTML={{__html: props.article.description}} />
				}

				{props.showMoreButton && pCount > 1 && 
					<button 
						className="btn btn--more" 
						onClick={(e) => {
							let btnElem = e.target;
							btnElem.closest('.section').classList.add('section--expanded');
							btnElem.remove();
						}}
					>
						{translations[props.lang].read_more}
					</button>
				}
			</div>
			
		</div>
	);

}

export default ArticleDefault;